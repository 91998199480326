import React from "react";
import { XCircleIcon } from "@heroicons/react/20/solid";

export default function Alert({ data }) {
  const renderedError = data.map((element, index) => {
    return <li key={index}>{element.message}</li>;
  });

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            Il y a {data.length} erreurs dans votre soumission
          </h3>
          <div className="mt-2 text-sm text-red-700">
            <ul className="list-disc space-y-1 pl-5">{renderedError}</ul>
          </div>
        </div>
      </div>
    </div>
  );
}
